<template>
  <router-link class="box-link" v-if="detailUrl" :to="detailUrl">
    <div class="box box-list">
      <article class="media">
        <div class="media-content">
          <div class="content">
            <div>
              <slot name="date" />
              <strong v-if="title">{{ title }}</strong>
            </div>
            <div v-if="subtitle1">
              <small>{{ subtitle1 }}</small>
            </div>
            <div v-if="subtitle2">
              <small>{{ subtitle2 }}</small>
            </div>
            <slot name="content" />
          </div>
        </div>
      </article>
    </div>
  </router-link>
  <div class="box-detail" v-else>
    <div class="box box-list">
      <article class="media">
        <div class="media-content">
          <div class="content">
            <div>
              <slot name="date" />
              <strong v-if="title">{{ title }}</strong>
            </div>
            <div v-if="subtitle1">
              <small>{{ subtitle1 }}</small>
            </div>
            <div v-if="subtitle2">
              <small>{{ subtitle2 }}</small>
            </div>
            <slot name="content" />
          </div>
        </div>
      </article>
    </div>
  </div>
</template>


<script>
export default {
  name: "Box",
  props: {
    title: String,
    subtitle1: String,
    subtitle2: String,
    detailUrl: Object,
  },
  // data() {
  //   return {
  //     valueData: this.value
  //   };
  // },
  // computed: {
  //   computedValue: {
  //     get() {
  //       return this.valueData;
  //     },
  //     set(value) {
  //       this.valueData = value;
  //       this.$emit("input", value);
  //     }
  //   }
  // },
  // watch: {
  //   // When v-model is changed: update internal value
  //   value(value) {
  //     this.valueData = value;
  //   }
  // }
};
</script>

<style scoped lang="scss">
.box-list {
  border-radius: unset;
  padding: 1rem 1.25rem;
}

a.box-link .box-list:hover {
  background: #eff6f9;
}
</style>